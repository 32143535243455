
import Vue from 'vue'
import Component from 'vue-class-component'
// import { Http } from '@/apis/Http'
import Support from '@/apis/Support'

@Component({
  name: 'Support'
})

export default class AdminSuppors extends Vue {
  search='';
  currentPage = 1
  pageCount = 1
  itemsPerPage = 50
  prevPage = 1
  supportList = {
    headers: [
      {
        text: '№',
        align: 'start',
        value: 'id',
        sortable: false
      },
      {
        text: 'ДЦ',
        align: 'start',
        value: 'dealership_name',
        sortable: false
      },
      {
        text: 'Ф.И.О',
        align: 'start',
        value: 'manager_fio',
        sortable: false
      },
      {
        text: 'Телефон',
        align: 'start',
        value: 'phone',
        sortable: false
      },
      {
        text: 'Необработ (более 24ч)',
        align: 'start',
        value: 'req_error',
        sortable: true
      },
      {
        text: 'Обработ',
        align: 'start',
        value: 'req_success',
        sortable: true
      },
      {
        text: 'Всего',
        align: 'start',
        value: 'req_total',
        sortable: true
      },
      {
        text: 'Процент',
        align: 'start',
        value: 'req_proc',
        sortable: true
      },
      {
        text: 'Время (чч:мм)',
        align: 'start',
        value: 'time',
        sortable: true
      },
      {
        text: 'Статус',
        align: 'start',
        value: 'status',
        sortable: false
      },
      {
        text: 'Коммент',
        align: 'start',
        value: 'comment',
        sortable: false
      },
      {
        text: 'Сохранить',
        align: 'start',
        value: 'empty',
        sortable: false
      }
    ],
    items: []
  };

  // @ts-ignore
  statuses: []
  created() {
    // @ts-ignore
    this.$setLoading(true)
    this.getSupportList()
  }

  navigate() {
    if (this.currentPage !== this.prevPage) {
      // @ts-ignore
      this.$setLoading(true)
      this.getSupportList()
    }
  }

  getSupportList() {
    const params = {
      page: this.currentPage,
      limit: this.itemsPerPage
    }
    Support.fetchSupportList(params)
      .then(({ data }) => {
        this.supportList.items = data.requests
        this.statuses = data.statuses
        this.pageCount = Math.ceil(data.total / this.itemsPerPage)
        // @ts-ignore
        this.prevPage = parseInt(this.currentPage)
      }).finally(() => {
      // @ts-ignore
        this.$setLoading(false)
      })
  }

  sendNewComment(userId: number, comment: string, statusId: number) {
    Support.sendNewComment(
      userId, comment, statusId
    )
      .then(({ data }) => {
        this.getSupportList()
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
        // this.supportList.items = data.requests
        // this.statuses = data.statuses
        // this.$setLoading(false)
      })
  }
}
