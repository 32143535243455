import { Http } from '@/apis/Http'
// import Csrf from '@/apis/Csrf'

export default {
  fetchSupportList(params = {}) {
    return Http.get('/admin/support', {
      params
    })
  },
  sendNewComment(userId: number, comment: string, statusId: number) {
    return Http.get('/admin/support/new', {
      params: {
        userId,
        comment,
        statusId
      }
    })
  }
}
